<template>
  <div class="my-5">
    <h4>
      {{
        new Date(current_period).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "long",
        })
      }}
      Accounting Entries ({{ filtered_entries.length }})
    </h4>
    <v-virtual-scroll
      :bench="14"
      :items="filtered_entries"
      max-height="550"
      min-height="50"
      item-height="40"
      class="my-5"
    >
      <template v-slot:default="{ item }">
        <v-list-item :key="item.journal_entry_id" class="mb-3">
          <v-list-item-content>
            <v-list-item-title class="sheet">
              <p>
                {{ item.journal_entry_date }}
                <span class="journal_entry_number"
                  ><a
                    style="text-decoration: none"
                    @click.stop="
                      $set(entryPreviewModal, item.journal_entry_id, true)
                    "
                    >{{ item.journal_entry_number }}
                  </a>
                  <!---->
                  <v-dialog
                    v-model="entryPreviewModal[item.journal_entry_id]"
                    max-width="850"
                    :key="item.journal_entry_id"
                  >
                    <JournalEntryPreviewModal :value="item" />
                  </v-dialog>
                </span>

                <span class="journal_entry_number">{{
                  item.account_name
                }}</span>
                <span class="float-right">{{
                  formatAsCurrency("R", item.entry_amount)
                }}</span>
              </p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script>
import db from "../../../components/firebaseInit";
import { formatAsCurrency } from "../../../composables/external";
export default {
  name: "IncomeStatementJournalEntryScroller",
  props: ["account", "current_period", "view_journal_entries"],
  components: {
    JournalEntryPreviewModal: () =>
      import("../components/journal_entries/JournalEntryPreviewModal.vue"),
  },
  data() {
    return {
      journal_entries: [],
      filtered_entries: [],
      benched: 0,
      entryPreviewModal: {},
    };
  },
  computed: {
    items() {
      return Array.from({ length: this.length }, (k, v) => v + 1);
    },
    length() {
      return 7000;
    },
  },
  created() {
    this.getJournalEntries(this.account.account_id);
  },
  watch: {
    account_id(newValue) {
      if (this.account.account_id === newValue) {
        this.account.account_id = newValue;
        this.getJournalEntries(this.account.account_id);
      } else {
        this.account.account_id = newValue;
      }
    },
  },
  methods: {
    formatAsCurrency,
    getJournalEntries(value) {
      // Get journal entries from database
      const docRef = db
        .collection("general_journal_entries")
        .where("journal_entry_date", ">=", `${this.current_period}-01`)
        .where("journal_entry_date", "<=", `${this.current_period}-31`);
      docRef.get().then((querySnapshot) => {
        this.journal_entries = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.journal_entries.push(data);
        });
        //  break up all the line items into an array of objects
        //  We have an array of deeply nested objects
        //  We want to loop through the array, push each line item to a new array, and flatten the object
        const obj_array = [];
        this.journal_entries.forEach((entry) => {
          entry.line_items.forEach((item) =>
            obj_array.push({
              journal_entry_id: entry.journal_entry_id,
              entry_amount: item.entry_amount,
              account_name: item.ledger_account.account_name,
              account_id: item.ledger_account.account_id,
              journal_entry_number: entry.journal_entry_number,
              journal_entry_date: entry.journal_entry_date,
              allocation_status: entry.bank_transaction.transaction_number,
            })
          );
        });
        this.filtered_entries = obj_array.filter((i) => i.account_id === value);
      });
    },
  },
};
</script>
<style scoped>
.sheet {
  font-size: 0.99em;
}
.journal_entry_number {
  display: inline-block;
  margin-left: 80px;
}
</style>
